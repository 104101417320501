import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './Pages/Home';
import Institucional from './Pages/Institucional';
import Maconaria from './Pages/Maconaria';
import Lojas from './Pages/Lojas';
import Contato from './Pages/Contato';


export const Routes = () => {
    return(
        <Switch>
            <Route path="/" exact><Home /></Route>
            <Route path="/home" sensitive><Home /></Route>
            <Route path="/institucional" sensitive><Institucional /></Route>
            <Route path="/historia" sensitive><Institucional /></Route>
            <Route path="/maconaria" sensitive><Maconaria /></Route>
            <Route path="/lojas" sensitive><Lojas /></Route>
            <Route path="/contato" exact sensitive><Contato /></Route>
            <Route path="*"><Home /></Route>
        </Switch>
    )
}
import React from 'react';
import Header from '../Components/Header';
import TitlePage from '../Components/TitlePage';
import Footer from '../Components/Footer';
import FormContato from '../Components/FormContato';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import qc from '../img/glmmb-qc2.jpg';
import MetaTags from 'react-meta-tags';

import '../Styles/Institucional.css';


function Institucional() {
    return (
      
     <div className="institucional">


          <MetaTags>
            <title>Institucional - Grande Loja Maçônica Mista do Brasil</title>
            <meta name="description" content="Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade." />
          </MetaTags>


        <Header />
        <TitlePage title="Institucional" subtitle="Conheça um pouco mais sobre a Grande Loja Maçônica Mista do Brasil"/>
        
        <div className="inicial-info">
            <Container maxWidth="lg">

            <Grid container  direction="row"  justify="space-around"  spacing={3}>

              <Grid  item xs={12} sm={6} lg={6}>
                <h2><small>O que é a</small>Grande Loja Maçônica Mista do Brasil</h2>
                <p>Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres.</p>
                  
                <h3>Com o objetivo de</h3>
                  
                   <p>promover a evolução pacífica da Humanidade pela prática constante das virtudes que elevam o ser humano e enaltecem o Grande Arquiteto do Universo, tais como a compreensão, a tolerância, a solidariedade, o perdão, dentre outros e também o combate aos vícios que o degradam diante do Criador.</p>
             
              </Grid>

              <Grid  item xs={12} sm={6} lg={6}>
              <h2><small>Quais são os nossos</small>Princípios</h2>
              <p>Amor à sabedoria e à verdade, o respeito mútuo, o altruísmo, os quais constituem a base do progresso da civilização, devendo seus membros lutar incessantemente contra todas as manifestações de intolerância, de violência e de ignorância.</p>
                
              <h3>Igualdade acima de tudo</h3>
              <p>Proclamar a Liberdade, Igualdade e Fraternidade entre os seres humanos, sem qualquer tipo de distinção, seja de raça, cor, sexo, idioma, religião ou condição social.</p>
             
              </Grid>

        </Grid>
        
            </Container>
        </div>



{/* 
        <p><strong>Entende que o trabalho Maçônico na busca da evolução só é possível com a participação de homens e mulheres em totais condições de igualdade.</strong></p> */}

        

        <div className="inst-destaque">
            <Container maxWidth="lg" >

            <Grid container  direction="row"  alignContent="center" justify="center" alignItems="center" spacing={0}>

              <Grid  item xs={12} sm={7} lg={7} className="conteudo-destaque">
                <p>Entendemos que o trabalho Maçônico na busca da <strong>evolução</strong> só é possível com a <strong>participação de homens e mulheres</strong> em totais condições de igualdade.</p>

                <p>Sobretudo, somos uma <strong>Instituição filosófica e cultural</strong>, que defende a liberdade de consciência como direito inalienável do ser humano.</p>

                <a href="/institucional/#historiaGrandeLoja" className="btn btn-small btn-blue">Conheça nossa história</a>

               
              </Grid>
              <Grid  item xs={12} sm={5} lg={5}>
              <img src={qc} className="img-fluid" alt="GLMMB"/>

              </Grid>
        </Grid>
        
            </Container>
        </div>











{/* <Divider /> */}

    <div className="timeline-info">
        <Container maxWidth="lg">
            <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

            <Grid  item xs={12} sm={12} lg={12}>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="fundacao-tab" data-toggle="tab" href="#fundacao" role="tab" aria-controls="fundacao" aria-selected="true"><span>1968</span> Início</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="fundador-tab" data-toggle="tab" href="#fundador" role="tab" aria-controls="fundador" aria-selected="false"><span>1968</span>Fundadores</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="sede-tab" data-toggle="tab" href="#sede" role="tab" aria-controls="sede" aria-selected="false"><span>1997</span>Sede</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="barreira-tab" data-toggle="tab" href="#barreira" role="tab" aria-controls="barreira" aria-selected="false"><span>2020</span>Atualmente</a>
                </li>
                </ul>
                
                
                <div className="tab-content" id="historiaGrandeLoja">

                {/* FUNDAÇÃO TAB     */}
                <div className="tab-pane fade show active" id="fundacao" role="tabpanel" aria-labelledby="fundacao-tab">
                    
                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={2}>

                <Grid item xs={12} sm={9} lg={9}>
                    <p><strong>Fundação da Grande Loja Maçônica Mista do Brasil</strong></p>
                    <p>O ano de 1968 ficou conhecido como "O ano que não terminou", entrando para a história como um ano extremamente movimentado e cheio de acontecimentos importantes, como o assassinato de Martin Luther King e de Robert Kennedy, a Guerra do Vietnã, além de inúmeras manifestações, sobretudo estudantis, contra a Guerra do Vietnã, contra a Guerra Fria e contra os regimes autoritários vigentes em diversos países do mundo, na América Latina e no Leste Europeu. No Brasil, o ano foi marcado pela instituição do AI-5 pelo então Presidente Costa e Silva.</p> 
{/*                 </Grid>
                <Grid item xs={6}> */}
                    <p>Foi em meio a este turbilhão de acontecimentos e fatos, que um grupo de irmãos (como se tratam, as pessoas que fazem parte da maçonaria) se reuniram, na capital do estado de São Paulo, no dia 24 de maio de 1968, para fundar a Grande Loja Maçônica Mista do Brasil, que inicialmente recebeu o nome de “Grande Loja Simbólica da Franco Maçonaria Mista do Estado de São Paulo”.</p> 
                    <p>Tomados pelo sentimento idealista de construção de uma nova maçonaria, com o espírito progressista e que reconhecesse, em pé de igualdade, os direitos e deveres dos seres humanos, efetivamente sem distinção de sexo, cor, condição social ou credo religioso.</p> 
                </Grid>

                </Grid>

                


                </div>

                {/* FUNDADORES TAB     */}
                <div className="tab-pane fade" id="fundador" role="tabpanel" aria-labelledby="fundador-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

                <Grid item xs={12} sm={9} lg={9} >
                <p><strong>Vale aqui, o registro histórico dos irmãos presentes nesta reunião de fundação da instituição:</strong></p>
                <p>José Hermenegildo Graciano Vieira da Luz, Jacob Fonseca dos Santos, Ofélia Martins de Araújo Cabral, Lina Libmoff e Alfredo Cabral. </p> 
                <p>O mestre maçom Alfredo Cabral foi designado a ocupar o cargo de Grão Mestre (equivalente ao cargo de Presidente) da nova obediência. </p>
       {/*       </Grid>
             <Grid item xs={5}> */}
              <p>Desta forma, passou a existir entre os maçons, uma opção séria, constituída através dos mais valorosos princípios que regem o ser humano, se postando a frente de seu tempo, estimulando por todos os meios admissíveis, a união, a fraternidade e a solidariedade que deva existir, a fim de que a harmonia e amizade franca e leal, reine na Instituição. </p>
              <p>Propugnara por todos os meios ao seu alcance, no sentido de que a chama do Ideal da <strong>Maçonaria Mista</strong> mantenha sempre acesa, isto é, a igualdade de direitos e obrigações entre homens e mulheres, que é a nossa bandeira.</p>
              <p>Alfredo Cabral manteve-se a frente de nossa instituição, até o seu falecimento, ocorrido em 1984. Nesta ocasião, a sede administrativa da GLMMB mudou-se para a cidade de Bauru/SP.</p>
             </Grid>

             </Grid>

                </div>

                {/* SEDE TAB     */}
                <div className="tab-pane fade" id="sede" role="tabpanel" aria-labelledby="sede-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

              <Grid item xs={12} sm={8} lg={8} >
              <p>A partir de 1997 a razão social da instituição deixou de ser “Sereníssima Grande Loja Simbólica da Franco Maçonaria Mista do Estado de São Paulo” para adotar o nome que vigora até os dias atuais: “Grande Loja Maçônica Mista do Brasil”. </p>  
              <p>Esta mudança se fez necessário porque os valores da instituição romperam os limites geográficos do estado de São Paulo e, atualmente, expandiu suas Lojas e trabalhos para os estados do Santa Catarina, Paraná, Rio de Janeiro e Minas Gerais.</p>
              <p>Sua sede foi transferida para São Paulo/Capital.</p>
{/*               </Grid>
             <Grid item xs={5}> */}
    <p>Desde então, tem atuado com muito brilhantismo nas esferas sociais, desde trabalhos de auxílio a sociedade e entidades filantrópicas, até o seu objetivo principal, que é tornar feliz a humanidade, através do aperfeiçoamento dos seus costumes e auxiliar o desenvolvimento do ser humano.</p>
    <p>Atualmente está localizada em 5 estados de nossa federação, com diversos tratado amizade, sendo reconhecida pela sua seriedade e retidão de seus trabalhos, em âmbito nacional e internacional.</p>

    
             </Grid>

             </Grid>
                </div>


                {/* BARREIRA TAB     */}
                <div className="tab-pane fade" id="barreira" role="tabpanel" aria-labelledby="barreira-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>
                
              <Grid item xs={12} sm={7} lg={7}>
              <p><strong>Rompendo Barreiras Socais</strong></p>
              <p>Grande Loja Maçônica Mista do Brasil rompeu mais uma <strong>barreira social</strong>, ao iniciar em seu quadro, no ano de 2015, um <strong>irmão cadeirante</strong>, que muito nos orgulha, pela oportunidade de reconhecer este brilhante ser humano, como um irmão. </p>

              <p><strong>Semente Maçônica</strong></p>
              <p>A ação Juvenil Semente Maçônica é uma instituição essencialmente educativa, filantrópica, assistencial e progressista, que visa preservar e proteger a identidade cultural da juventude brasileira, e estimular o espírito de serviço à comunidade com valores éticos-morais e espiritualistas, patriotismo, desporto a arte (plástica, cênicas, literárias, escultural, oratória, etc)</p>

              <p><strong>Atualmente...</strong></p>
              <p>Com mais de <strong>50 anos de trabalho</strong> contínuos, a Grande Loja Maçônica Mista do Brasil se orgulha de suas conquistas, avanços sociais e dos seres humanos que construíram esta história, mantendo-se ativa e próspera, para escrever novas páginas de sucesso e proporcionar a seus associados, uma experiência única de evolução social e espiritual.</p>
           
             
              </Grid>

              </Grid>

                </div>

                {/* ATUALMENTE TAB     */}
 {/*                <div className="tab-pane fade" id="atual" role="tabpanel" aria-labelledby="atual-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

              <Grid item xs={12} sm={5} lg={5} >
              <h4></h4>
            </Grid>

              </Grid>

  

                </div> */}

           

                </div>

         

            </Grid>

   {/*          <Grid item xs={12} sm={3} lg={3}>
              <div className="institucional-logo">
                <img src={Logo}  />
              </div>
            </Grid>
 */}
        </Grid>
        </Container>
        </div>


        <FormContato />
        <Footer />
      </div> 
  );
}

export default Institucional;


import React from 'react';
import Header from '../Components/Header';
import TitlePage from '../Components/TitlePage';
import Footer from '../Components/Footer';
import FormContato from '../Components/FormContato';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import esquadro from '../img/img-g.jpg';
import MetaTags from 'react-meta-tags';


import '../Styles/Maconaria.css';



function Maconaria() {


    return (
     <div className="maconaria">

          <MetaTags>
            <title>Maçonaria - Grande Loja Maçônica Mista do Brasil</title>
            <meta name="description" content="Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade." />
          </MetaTags>


        <Header />
        <TitlePage title="Maçonaria" subtitle="Entenda e tire suas dúvidas sobre a Maçonaria Mista"/>



        <div className="duvidas-maconaria">
            <Container maxWidth="lg">

            <Grid container  direction="row"  justify="center" alignItems="center" spacing={3}>

              <Grid  item xs={12} sm={4} lg={4}>
                <h2><small>O que é a</small>Maçonaria?</h2>
                <p>Maçonaria é uma instituição que tem por objetivo tornar feliz a humanidade pelo amor, pelo aperfeiçoamento dos costumes, pela tolerância, pela igualdade e pelo respeito à autoridade e à crença de cada um, sem qualquer distinção.</p>
                  
               
                <h2><small>O que é necessário</small>para entrar na Maçonaria?</h2>
                <p>Ser livre e de bons costumes.</p>

             
              </Grid>

              <Grid  item xs={12} sm={5} lg={5}>
              <img src={esquadro} className="img-fluid" alt="Maçonaria - GLMMB"/>

              </Grid>

        </Grid>
        
            </Container>
        </div>

{/* <Divider /> */}

        <div className="maconaria-questionario">
            <Container maxWidth="lg" >

            <Grid container  direction="row"  alignContent="center" justify="center" alignItems="center" spacing={4}>

              <Grid  item xs={12} sm={4} lg={4} >
              <h2><small>Analisemos essas duas máximas, aplicadas universalmente,</small> independente de Rito ou Obediência.</h2>

              </Grid>
             
              <Grid  item xs={12} sm={6} lg={6} >

              <p><strong>É possível...</strong></p>

              <FormControlLabel control={ <Checkbox color="primary" />}
                label="Tornar feliz a humanidade pelo amor, excluindo a mulher?"
              />    

              <FormControlLabel control={ <Checkbox color="primary" />}
                label="Aperfeiçoar os costumes excluindo a mulher?"
              />  

              <FormControlLabel control={ <Checkbox color="primary" />}
                label="Defender a tolerância e a igualdade, excluindo a mulher?"
              />    

{/*               <FormControlLabel control={ <Checkbox color="primary" />}
                label="Ser livre: a mulher não é livre?"
              />         
               */}
{/*               <FormControlLabel control={ <Checkbox color="primary" />}
                label="E finalmente, a mulher não é dotada de bons costumes?"
              />    
 */}


     

              </Grid>

        </Grid>
        
            </Container>

            <div className="resultado-questionario">
              <p>Se você respondeu ao menos uma das questões com <span>"SIM"</span>,<br/> <strong>não se identificará</strong> com nossos pensamentos.</p>
           </div>

        </div>

        <div className="entenda-historico">
            <Container maxWidth="lg" >

            <Grid container  direction="row"   justify="center" >

              <Grid  item xs={12} sm={6} lg={6}>
                <h3><small>Pra entender melhor,</small> vamos analisar o contexto histórico?</h3>

                <p>Preparamos o conteúdo abaixo para que você possa conhecer um pouco sobre a história da maçonaria, desde a operativa, suas transformações e como está atualmente.</p>

              </Grid>
        </Grid>
        
            </Container>
        </div>



        <div className="timeline-maconica">
        <Container maxWidth="lg">
            <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

            <Grid  item xs={12} sm={12} lg={12}>
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="operativa-tab" data-toggle="tab" href="#operativa" role="tab" aria-controls="operativa" aria-selected="true"><span>Maçonaria</span> Operativa</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="transformacao-tab" data-toggle="tab" href="#transformacao" role="tab" aria-controls="transformacao" aria-selected="false"><span>Trasformação</span>Maçonaria Atual</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="especulativa-tab" data-toggle="tab" href="#especulativa" role="tab" aria-controls="especulativa" aria-selected="false"><span>Maçonaria</span>Especulativa</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="mulheres-tab" data-toggle="tab" href="#mulheres" role="tab" aria-controls="mulheres" aria-selected="false"><span>Mulheres</span>na Maçonaria</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="direitos-tab" data-toggle="tab" href="#direitos" role="tab" aria-controls="direitos" aria-selected="false"><span>Conquista</span>pelos direitos</a>
                </li>
                </ul>
                
                
                <div className="tab-content" id="historiaMaconaria">

                {/* OPERATIVA TAB     */}
                <div className="tab-pane fade show active" id="operativa" role="tabpanel" aria-labelledby="operativa-tab">
                    
                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={2}>

                <Grid item xs={12} sm={9} lg={9} >
                
                  <p>Foi no Império Romano, em função da própria atividade bélica que surgiu, no século VI A.C. a primeira associação organizada de construtores, os Collegia Fabrorum. Como a conquista das vastas regiões da Europa, da Ásia e do norte da África, levava à destruição, os collegiati acompanhavam as legiões romanas, para reconstruir o que fosse sendo destruído pela guerra. </p>

                  <p>Na Idade Média é que iria florescer, a hoje chamada Maçonaria Operativa, ou Maçonaria de Ofício, para a preservação da Arte Real entre os mestres construtores da Europa. Assim, a partir do século VI, eram as Associações de construtores que por dominar o segredo da arte de construir, tinham franqueado seu acesso a praticamente todas as cidades de vários países. </p>

                  <p>O fortalecimento dos construtores culminou na criação das Guildas, que nada mais eram do que oficinas para formação de novos profissionais, porém para ser aceito em uma oficina era necessário ter origem nobre e ser convidado por um dos obreiros da oficina. </p>
                  
                </Grid>

                </Grid>

                


                </div>

                {/* TRANSFORMAÇÃO TAB     */}
                <div className="tab-pane fade" id="transformacao" role="tabpanel" aria-labelledby="transformacao-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

                <Grid  item xs={12} sm={9} lg={9} >
             
                  <p><strong>Iniciava-se a transformação na Maçonaria atual.</strong></p>
                  <p>A Loja de pesquisa mais importante e respeitada em todo o mundo é a Loja "Quatuor Coronati" de Londres, existente há mais de 100 anos, levantou o maior acervo documental sobre as origens da Maçonaria. E é de seus registros históricos que tiramos algumas comprovações da participação feminina na Maçonaria Operativa.</p>
                  <p>Na França, o Livro dos Ofícios, de 1268; na Inglaterra, os Estatutos da Guilda de Norwich, de 1375, afirmavam em seus Estatutos:
                  “Aquele ou aquela que se tornar Maçom deve fazer seus juramentos colocando a mão sobre a Bíblia”.
                  </p>

                  <p>Há também o Manuscrito Régio, de 1390, universalmente aceito e que tratava das relações entre maçons, em seu Artigo 10, versos 203 e 204, diz: "que nenhum Mestre suplante o outro, sendo que procedam como irmão e irmã”. </p>
             
                </Grid>

             </Grid>

                </div>

                {/* ESPECULATIVA TAB     */}
                <div className="tab-pane fade" id="especulativa" role="tabpanel" aria-labelledby="especulativa-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

              <Grid item xs={12} sm={9} lg={9}  >
         
         <p>Perdendo o seu objetivo inicial e transformando-se em sociedade de auxílio mútuo, resolveram, então, permitir a entrada de homens não ligados à arte de construir, não profissionais, que eram, então, chamados de maçons aceitos. As corporações, evidentemente, começaram por admitir pessoas em pequeno número e selecionadas entre os homens conhecidos pelos seus dotes culturais, pelo seu talento e pela sua condição aristocrática, que poderiam dar projeção a elas, submetendo-se, todavia, aos seus regulamentos. Era a tentativa de sustar o declínio. </p>

         <p>Por outro lado, as corporações, por perderem seu caráter meramente de construção e se dedicar aos estudos filosóficos, pela cultura trazida pelos aceitos, começaram a ser perseguidos pela Igreja, justamente no auge da "Santa Inquisição", tribunal que tinha como objetivo, punir os crimes contra a fé católica. </p>

         <p>Assim inúmeros Maçons foram perseguidos e assassinados pela igreja, fazendo com que muitas Lojas fossem fechadas e seus obreiros se escondessem. As poucas Lojas que se mantiveram, tinham suas reuniões na clandestinidade mantendo em sigilo absoluto, com extremo cuidado, a identidade dos Maçons. Conseguiram se manter por encontrarem uma maneira de se reunir sem levantar suspeitas, foi quando começou o período em que as Lojas tinham suas reuniões em Tabernas, ou bares fechados, que eram freqüentados somente por homens.

</p>

        <p>As Lojas da época adotavam o nome do lugar onde se reuniam, ou o nome da igreja ou da taberna, tanto que em 1717, quatro das principais Lojas se reuniram para traçar planos referentes à alteração da estrutura maçônica.  </p>

        <p>As Lojas presentes foram, "A Macieira", “O Ganso e a Grelha”:  "A Coroa" e a "Copázio e as Uvas". Como resultado dessa reunião foi fundada a primeira Grande Loja e instituído o sistema obediencial, com Lojas subordinadas a um poder central, sob a direção de um Grão-Mestre, já que, antes disso, as Lojas eram livres de qualquer subordinação externa. </p>


             </Grid>

             </Grid>
                </div>



                {/* MULHERES NA MAÇONARIA TAB     */}
                <div className="tab-pane fade" id="mulheres" role="tabpanel" aria-labelledby="mulheres-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>

              <Grid item xs={12} sm={9} lg={9}  >
   
              <p><strong>Evidentemente que esse foi um período em que a mulher foi dissociada da Maçonaria, por duas importantes e óbvias razões:</strong> </p>

              <p>- A igreja, que detinha o poder absoluto, renegava a mulher um papel secundário na sociedade, inclusive nessa época a mulher não tinha nem alma, segundo os princípios do clero.</p>

              <p>- A Maçonaria, por ser perseguida pela Igreja e se reunir em locais onde a mulher não podia freqüentar, passou todo esse período sem incluir nenhuma mulher em seus quadros, criando naturalmente a cultura de que a Maçonaria era uma ordem exclusivamente masculina.</p>

              <p>E foi nesse com esse conceito que em 1723, a Grande Loja da Inglaterra aprovou sua Constituição, conhecida como a "Constituição de Anderson" o primeiro documento que proíbe textualmente a participação da Mulher na Maçonaria, invocando para isso os Landmarks, ou Regras, que seria uma codificação dos usos e costumes.</p>

              <p>O texto dessa Constituição, deve ser visto pelo ângulo do momento histórico, numa época em que era comum o trabalho escravo e em que as mulheres não possuíam nenhum dos direitos civis, não sendo, portanto, livres. Não se pode pegar um texto do início do século XVIII e querer analisá-lo à luz da época e dos costumes atuais, quase 300 anos depois.</p>

              <p>O veto ao ingresso de mulheres na Maçonaria regular masculina, é decretado por um dos Oito Princípios de Regularidade da Grande Loja Unida da Inglaterra, Documento escrito posterior à sua Constituição e que teve como objetivo regular as relações com as diversas Obediências que assinaram um Tratado de Amizade, documento que dá reconhecimento umas as outras.</p>

              <p>Cabe enfatizar outros documentos registrados, como os Estatutos da Loja de York, de 1693. Estes Estatutos assim se expressavam: “Aquele ou aquela que se tornar Maçom deve fazer seus juramentos colocando a mão sobre a Bíblia”.  Documentos ingleses de 1663, 1696 e 1713, citam nominalmente mulheres-maçons.</p>


              </Grid>

              </Grid>

  

                </div>




                {/* CONQUISTA PELOS DIREITOS TAB     */}
                <div className="tab-pane fade" id="direitos" role="tabpanel" aria-labelledby="direitos-tab">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>
                
              <Grid item xs={12} sm={9} lg={9} >
             
             
             <p>Ao contrário do que muitos pensam, as mulheres já resgataram seu direito de ingressar na Maçonaria há mais de 100 anos.</p>

             <p>Em 1893 foi criada a primeira Loja Maçônica Mista, que deu origem a primeira Obediência Mista no mundo, "O Direito Humano", hoje estruturada no mundo todo.</p>

             <p>Annie Besant, célebre feminista inglesa, e membro da “Le Droit Humain”, disse:</p>

             <p><strong>“Se é verdade que foram os ingleses que trouxeram a maçonaria para a França, então são os Franceses, hoje, que a devolvem à Inglaterra, regenerada, completa e fortalecida pela admissão da mulher na Loja ao lado do homem”.</strong></p>

             <p>O processo de transformação que a humanidade está passando é muito mais acelerado que em mudanças culturais ocorridas anteriormente, visto que a globalização promove essas mudanças com muito mais amplitude e rapidez.</p>

             <p><strong>A Maçonaria, que sempre esteve à frente dessas mudanças, está vivendo uma crise justamente por estar atrelada a uma cultura meramente patriarcal.</strong></p>

             <p>Em 1968, Alfredo Cabral, Maçom do Grande Oriente do Brasil, um Irmão que dedicou sua vida em prol da Maçonaria e que mesmo durante o período de maior repressão no Brasil, no auge da ditadura militar, ousou romper com os valores da época e convicto, pela experiência adquirida, de que para se fazer Maçonaria de uma maneira completa, era necessário ter a participação da mulher, fundou a Grande Loja Maçônica Mista do Brasil.</p>
             
             <p>Um irmão com visão muito a frente de seu tempo, enfrentou muitos obstáculos, ainda assim, entendeu que só valeria a pena continuar a fazer Maçonaria, acatando um dos princípios basilares da Ordem, ou seja, a Igualdade. </p>

             <p>Entendemos que o trabalho maçônico na busca da evolução só é possível com a participação de homens e mulheres em totais condições de igualdade!</p>
             
             <p>Proclamamos a Liberdade, Igualdade e Fraternidade entre os seres humanos de ambos os sexo, independente de raça, credo ou condição social. Consagramos como princípios indispensáveis: o amor à sabedoria e à verdade, o respeito mútuo, o altruísmo, os quais constituem a base do progresso da civilização, devendo seus membros lutar incessantemente contra todas as manifestações de intolerância, de violência e de ignorância. </p>
             
             
              </Grid>

              </Grid>

                </div>



           

                </div>

         

            </Grid>


        </Grid>
        </Container>
        </div>












        <FormContato />
        <Footer />
      </div>
  );
}

export default Maconaria;


import React, { useEffect }  from 'react';
import { Link } from 'react-router-dom';

import Logo from '../img/logo-glmmb.png';
import '../Styles/Header.css';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';


import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import ReactGA from 'react-ga';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    display: 'none',
  },
  hide: {
    display: 'none',
  },
  '@media (max-width: 600px)': {
    menuButton: {
      display: 'block',
      float: 'left',
      padding: '30px 0 25px 20px',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  alignment:{
      display: 'flex',
      justifyContent: 'space-between',

  },



}));




export default function Header() {

/* GOOGLE ANALITYCS */
useEffect(() => {
  ReactGA.initialize('UA-154674421-1');
  // To Report Page View 
  ReactGA.pageview(window.location.pathname + window.location.search);
}, [])



  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
    <CssBaseline />
    
      <AppBar position="fixed" className="header-nav">
      <Container maxWidth="lg">
      <IconButton edge="start" className={clsx(classes.menuButton, open && classes.hide)} color="inherit" aria-label="open drawer" onClick={handleDrawerOpen}>
            <MenuIcon/>
      </IconButton>

        <Toolbar className={classes.alignment} >
          <div className="logo">
              <img src={Logo} alt="Logo Grande Loja Maçônica Mista do Brasil"/>
              <Typography className="header-titulo">
                Grande Loja Maçônica <br/> Mista do Brasil
            </Typography>
          </div>
          <div className="navbar">
            <Link to="/" >Home</Link>
            <Link to="/institucional" >Institucional</Link>
            <Link to="/maconaria" >Maçonaria</Link>
            <Link to="/lojas" >Lojas</Link>
            <Link to="/contato" className="btn-contato">Contato</Link>
         </div>
        </Toolbar>
        </Container>
      </AppBar>


      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />} Fechar
          </IconButton>
        </div>
        <Divider />
        <List>

            <ListItem button component={Link} to="/">
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/institucional">
              <ListItemText primary="Institucional" />
            </ListItem>
            <ListItem button component={Link} to="/maconaria">
              <ListItemText primary="Maçonaria" />
            </ListItem>
            <ListItem button component={Link} to="/lojas">
              <ListItemText primary="Lojas" />
            </ListItem>
            <ListItem button component={Link} to="/contato">
              <ListItemText primary="Contato" />
            </ListItem>
        </List>
      </Drawer>



   
    </React.Fragment>
  );
}

import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';


import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone';
import ForumTwoToneIcon from '@material-ui/icons/ForumTwoTone';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import TextField from '@material-ui/core/TextField';
import ChatTwoToneIcon from '@material-ui/icons/ChatTwoTone';

import Button from '@material-ui/core/Button';

import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import '../Styles/FormContato.css';

   
function FormContato() {
     const [age, setAge] = React.useState('');

    return (
        <div className="formContato">
           <div className="formContato-overlay">

              <h3>Quer saber mais?</h3>
              <p>Acompanhe a Grande loja nas redes sociais e fique por dentro das novidades. <br/> Estamos reformulando nossos canais e site para você!</p>

{/*               <form className="col-lg-4">
                <div class="form-group">
                  <label for="email">Email address</label>
                  <input type="email" class="form-control" id="email" />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <input type="text" class="form-control" id="exampleInputPassword1" />
                </div>
                <button type="submit" class="btn btn-primary">Submit</button>
              </form> */}


              <Link to="/contato" className="btn btn-small btn-blue">Contato</Link>

            </div>






{/*             <Grid container  direction="row"  justify="space-around"  spacing={3}>   

              <Grid item xs={4}>

               <ChatTwoToneIcon className="iconFormContato"/>
               <h3>Contato <br/><small>Aproveite este espaço para tirar suas dúvidas ou enviar sugestões.</small></h3>
              </Grid>

              <Grid item xs={7}>

              <form className="form"  fullwidth noValidate autoComplete="off">

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>              
                <Grid item xs={6}>
                  <TextField required id="name"  label="Nome" variant="outlined" className="input-md"/>
                </Grid>
                <Grid item xs={6}>
                  <TextField required id="email" label="E-mail" variant="outlined" type="email"  className="input-md"/>
                </Grid>
                </Grid>

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>              
                <Grid item xs={6}>
                  <TextField required id="phonenumber" label="Celular" variant="outlined" type="tel" className="input-md" />
                </Grid>
                <Grid item xs={6}>
                <FormControl variant="outlined" 
                    className="input-md" >
                  <InputLabel id="demo-simple-select-outlined-label" >Assunto</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={age}
                    label="Age"
                  >
                    <MenuItem value="">
                      <em>Nenhum</em>
                    </MenuItem>
                    <MenuItem value={10}>Interesse</MenuItem>
                    <MenuItem value={10}>Sugestão</MenuItem>
                    <MenuItem value={20}>Parceria</MenuItem>
                    <MenuItem value={30}>Dúvidas</MenuItem>
                  </Select>
                </FormControl>
                </Grid>
                </Grid>

                <Grid container  direction="row"  justify="space-around" alignItems="center" spacing={3}>              
                <Grid item xs={12}>
                  <TextField required id="message" label="Mensagem" variant="outlined"  labelWidth={60}  multiline className="input-md"/> 
                </Grid>
                </Grid>


                <Grid item xs={12}>
                  <Button variant="contained" size="large" color="primary" type="submit">
                  Enviar
                </Button>
                </Grid>

              </form>

              </Grid>
            </Grid>
 */}


</div>
  );
}

export default FormContato;


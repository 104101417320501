import React from 'react';
import Container from '@material-ui/core/Container';
import '../Styles/TitlePage.css';

   
function TitlePage(props) {
    return (
        <div className="title-page bg-contato">
            <div className="overlay">
            <Container maxWidth="lg">
                <h1>{props.title}</h1>
                <h2 className="subtitle">{props.subtitle}</h2>
            </Container>
        </div>
        </div>
  );
}

export default TitlePage;


import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import loja from '../img/loja.jpg';
import video from '../img/player.jpg';
import FormContato from '../Components/FormContato';
import '../Styles/Home.css';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import MetaTags from 'react-meta-tags';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



function Home() {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


    return (
     <div className="Home">

          <MetaTags>
            <title>Grande Loja Maçônica Mista do Brasil</title>
            <meta name="description" content="Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade." />
          </MetaTags>

        <Header />
 

      <div className="home-principal">
      <div className="home-overlay">
        <Container maxWidth="lg">
        <Grid container  direction="row"  spacing={3}>
              <Grid item xs={12} sm={6} lg={6}>
                <h1>Grande Loja Maçônica Mista do Brasil</h1>
                <p>Associação Universal e Fraterna, de <strong>pessoas de ambos os sexos</strong>, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade.</p>

                  <p>Entendemos que o trabalho Maçônico na busca da evolução só é possível com a  <strong>participação de homens e mulheres</strong> em totais condições de <strong>igualdade</strong>.</p>
                  <Link to="/institucional" className="btn btn-small btn-blue">Conheça nossa instituição!</Link>
              </Grid>
        </Grid>

        
        </Container>
      </div>
      </div>



      <div className="home-glmmb">
      <Container maxWidth="lg">
        <Grid container    justify="center" alignItems="center" spacing={3}>
               <Grid item xs={12} sm={3} lg={3}>
                  <h1>#SomosTodos</h1>
                  <h2>GLMMB</h2>
                  <p>Entendemos que o trabalho Maçônico na busca da evolução só é possível com a participação de homens e mulheres em totais condições de igualdade.</p>
               </Grid>

               <Grid item xs={12} sm={5} lg={5}>
               <img src={video} className="img-fluid img-video-home" onClick={handleClickOpen} alt="Video GLMMB"/>
              </Grid>


              <div>
                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={handleClose}
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
            
            
                    <iframe id="ytplayer" type="text/html" width="520" height="360" title="video"
                    src="https://www.youtube.com/embed/gdvmsXY7zUo?autoplay=1&fs=0&color=white"
                    allowFullScreen />

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            FECHAR
          </Button>
        </DialogActions>
      </Dialog>
    </div>










              

{/*               <Grid item xs={12} sm={8} lg={8}>
              <div className="video-glmmb">
                <iframe width="100%" height="450" src="https://www.youtube.com/embed/gdvmsXY7zUo"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" ></iframe>
              </div>
              </Grid> */}
        </Grid>
        </Container>
      </div>


      <div className="home-macmista">
      <Container maxWidth="lg">
        <Grid container  direction="row"  spacing={3}>
              <Grid item xs={12} sm={6} lg={6}>
                  <h2>Maçonaria Mista?</h2>

                  <p>Ao contrário do que muitos pensam, as mulheres já resgataram seu direito de ingressar na Maçonaria há mais de 100 anos.</p>
                  <p>Em 1893 foi criada na França a primeira Loja Maçônica Mista, que deu origem à primeira Obediência Mista no mundo, "O Direito Humano", atualmente estruturada no mundo todo.</p>
          
                  <Link to="/maconaria" className="btn btn-small btn-blue">Saiba mais</Link>
              </Grid>
{/*               <Grid item xs={12} sm={4} lg={4}>
                  6
              </Grid> */}
        </Grid>
        </Container>
      </div>




      <div className="home-lojas">
      <Container maxWidth="lg">
        <Grid container  direction="row"  justify="center" alignItems="center" spacing={3}>
              <Grid item xs={12} sm={4} lg={4}>
              <img src={loja} className="img-fluid" alt="cidades"/>
              </Grid>
              <Grid item xs={12} sm={5} lg={5} className="conteudo-lojas">
                  <p>Estamos presentes em</p>
                  <h2>12 CIDADES</h2>
                  <p>e nossas lojas estão espalhadas pelos estados de RS, MG, RJ, PR, SC e SP</p>
          
                  <Link to="/maconaria" className="btn btn-small btn-blue">Saiba mais</Link>
              </Grid>
            
        </Grid>
        </Container>
      </div>


        <div class="feed-instagram">
          <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js"></script>
          <script id="SWWJS" BaseUrl="https://www.socialwebwidgets.com/" ApiKey="a7aba4e0a5349f700210de2dbf8bce4f" WidgetID="326" type="text/javascript" src="https://www.socialwebwidgets.com/assets/CDN/sww.min.js"></script>
          <div id="SWW"></div>
        </div>

        <FormContato />

        <Footer />

      </div>
  );
}

export default Home;


import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Container from '@material-ui/core/Container';
import FormContato from '../Components/FormContato';

import Grid from '@material-ui/core/Grid';

import '../Styles/Lojas.css';
import TitlePage from '../Components/TitlePage';

import MetaTags from 'react-meta-tags';


function Lojas() {
    return (
     <div className="lojas">

		 <MetaTags>
            <title>Lojas - Grande Loja Maçônica Mista do Brasil</title>
            <meta name="description" content="Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade." />
          </MetaTags>

        <Header />
        <TitlePage title="Lojas" subtitle="Estamos em 12 cidades no Brasil, localize-se."/>

        <div className="lojasList">
            <Container maxWidth="lg">

			<Grid container  direction="row"  justify="center" >
			
			<Grid item xs={12} sm={2} lg={2}>

			<div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
				<a className="nav-link active" id="v-pills-poa-tab" data-toggle="pill" href="#v-pills-poa" role="tab" aria-controls="v-pills-poa" aria-selected="true">Porto Alegre/RS</a>
				<a className="nav-link" id="v-pills-divinopolis-tab" data-toggle="pill" href="#v-pills-divinopolis" role="tab" aria-controls="v-pills-divinopolis" aria-selected="true">Divinópolis/MG</a>
				<a className="nav-link" id="v-pills-niteroi-tab" data-toggle="pill" href="#v-pills-niteroi" role="tab" aria-controls="v-pills-niteroi" aria-selected="false">Niterói/RJ</a>
				<a className="nav-link" id="v-pills-curitiba-tab" data-toggle="pill" href="#v-pills-curitiba" role="tab" aria-controls="v-pills-curitiba" aria-selected="false">Curitiba/PR</a>
				<a className="nav-link" id="v-pills-joinville-tab" data-toggle="pill" href="#v-pills-joinville" role="tab" aria-controls="v-pills-joinville" aria-selected="false">Joinville/SC</a>
				<a className="nav-link" id="v-pills-bauru-tab" data-toggle="pill" href="#v-pills-bauru" role="tab" aria-controls="v-pills-bauru" aria-selected="false">Bauru/SP</a>
				<a className="nav-link" id="v-pills-taquaritinga-tab" data-toggle="pill" href="#v-pills-taquaritinga" role="tab" aria-controls="v-pills-taquaritinga" aria-selected="false">Taquaritinga/SP</a>
				<a className="nav-link" id="v-pills-campinas-tab" data-toggle="pill" href="#v-pills-campinas" role="tab" aria-controls="v-pills-campinas" aria-selected="false">Campinas/SP</a>
				<a className="nav-link" id="v-pills-socorro-tab" data-toggle="pill" href="#v-pills-socorro" role="tab" aria-controls="v-pills-socorro" aria-selected="false">Socorro/SP</a>
				<a className="nav-link" id="v-pills-matao-tab" data-toggle="pill" href="#v-pills-matao" role="tab" aria-controls="v-pills-matao" aria-selected="false">Matão/SP</a>
				<a className="nav-link" id="v-pills-jaboticabal-tab" data-toggle="pill" href="#v-pills-jaboticabal" role="tab" aria-controls="v-pills-jaboticabal" aria-selected="false">Jaboticabal/SP</a>
				<a className="nav-link" id="v-pills-saopaulo-tab" data-toggle="pill" href="#v-pills-saopaulo" role="tab" aria-controls="v-pills-saopaulo" aria-selected="false">São Paulo/SP</a>
			</div>

			</Grid>

			<Grid item xs={12} sm={7} lg={7}>


			<div className="tab-content" id="v-pills-ListaLojas">

            <div className="tab-pane fade show active" id="v-pills-poa" role="tabpanel" aria-labelledby="v-pills-poa-tab">
					<h2>Porto Alegre <small>Rio Grande do Sul</small></h2>

					<div className="boxLojas">
						<h5><small></small>Em breve...</h5>
					</div>
				</div>


				<div className="tab-pane fade" id="v-pills-divinopolis" role="tabpanel" aria-labelledby="v-pills-divinopolis-tab">
					<h2>Divinópolis <small>Minas Gerais</small></h2>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small> Templários da Arte Real Nº37</h5>
						<p>Reuniões: Segundas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Antonio Amaral, 190 - Capitão Silva, Divinópolis/MG</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-niteroi" role="tabpanel" aria-labelledby="v-pills-niteroi-tab">
				<h2>Niterói <small>Rio de Janeiro</small></h2>
					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small> Místicos Construtores Nº43</h5>
						<p>Reuniões: Sábados às 10h (Quinzenal)</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Djalma Dutra, 28, Centro, Niterói/RJ</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-curitiba" role="tabpanel" aria-labelledby="v-pills-curitiba-tab">
				<h2>Curitiba <small>Paraná</small></h2>
					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small> Harmonia Fraterna Nº17</h5>
						<p>Reuniões: Quartas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Carlos Essenfelder, 2047, Bairro Boqueirão, Curitiba/PR</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-joinville" role="tabpanel" aria-labelledby="v-pills-joinville-tab">
				<h2>Joinville <small>Santa Catarina</small></h2>
					<div className="boxLojas">
						<h5><small>&nbsp;</small>Triângulo I</h5>
						<p>Reuniões: Segundas-feiras às 19:30h</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua 15 de Novembro, 357, Centro, Joinville/SC</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-bauru" role="tabpanel" aria-labelledby="v-pills-bauru-tab">
				<h2>Bauru <small>São Paulo</small></h2>
					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>  Liberdade Nº13 Fauaz Abdala</h5>
						<p>Reuniões: Sábados às 10h (Quinzenal)</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Princesa Izabel, 13-83,  Lemos, Bauru/SP</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-taquaritinga" role="tabpanel" aria-labelledby="v-pills-taquaritinga-tab">
				<h2>Taquaritinga <small>São Paulo</small></h2>
					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>  Evolução Alfio Sampieri Nº18</h5>
						<p>Reuniões: Quintas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Av. Thyrso Micalli, 333, Taquarão II, Taquaritinga/SP</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-campinas" role="tabpanel" aria-labelledby="v-pills-campinas-tab">
				<h2>Campinas <small>São Paulo</small></h2>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>  Phoênix Nº25</h5>
						<p>Reuniões: Segundas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i>Rua Raquel Rosa da Silva, 142, Nova América, Campinas/SP</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-socorro" role="tabpanel" aria-labelledby="v-pills-socorro-tab">
				<h2>Socorro <small>São Paulo</small></h2>
					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>Amor e União Nº35</h5>
						<p>Reuniões: Sábados às 20h30 (Quinzenal)</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Coronel Florêncio Experidião, 181, Centro, Socorro/SP</p>
					</div>
				</div>

				<div className="tab-pane fade" id="v-pills-matao" role="tabpanel" aria-labelledby="v-pills-matao-tab">
				<h2>Matão <small>São Paulo</small></h2>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small> Igualdade e Perseverança Nº38</h5>
						<p>Reuniões: Terças-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Avenida Araraquara, 471, Jd. Buscardi, Matão/SP</p>
					</div>
				</div>
				
				<div className="tab-pane fade" id="v-pills-jaboticabal" role="tabpanel" aria-labelledby="v-pills-jaboticabal-tab">
				<h2>Jaboticabal <small>São Paulo</small></h2>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small> Igualdade e Harmonia Nº5</h5>
						<p>Reuniões: Quartas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Benedito Constantino, 241, Residencial Verde Ville, Jaboticabal/SP</p>
					</div>
				</div>
				
				<div className="tab-pane fade" id="v-pills-saopaulo" role="tabpanel" aria-labelledby="v-pills-saopaulo-tab">
				<h2>São Paulo <small>São Paulo</small></h2>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>O Grande Arcano Nº8</h5>
						<p>Reuniões: Segundas-feiras às 20h</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Professor Marcondes Domingues, 293, Parada Inglesa, São Paulo/SP</p>
					</div>

					<div className="boxLojas">
						<h5><small>A∴R∴L∴M∴S∴M∴</small>  Yoheshua Ben Pandira Nº11</h5>
						<p>Reuniões: Sextas-feiras às 20h (Quinzenal)</p>
						<p><i className="fas fa-map-marker-alt"></i> Rua Profa Lygia de Azevedo Sá, 42, Saúde, São Paulo/SP</p>
                   </div>

                  <div className="boxLojas">
                    <h5><small>&nbsp;</small>Triângulo III</h5>
                    <p>Reuniões: Quintas-feiras às 20h (Quinzenal)</p>
                    <p><i className="fas fa-map-marker-alt"></i> Rua Profa Lygia de Azevedo Sá, 42, Saúde, São Paulo/SP</p>
                  </div>

                  <div className="boxLojas">
                    <h5><small>&nbsp;</small>Triângulo IV</h5>
                    <p>Reuniões: Segundas-feiras às 20h (Quinzenal)</p>
                    <p><i className="fas fa-map-marker-alt"></i> Rua Profa Lygia de Azevedo Sá, 42, Saúde, São Paulo/SP</p>
                  </div>

				</div>
			</div>

			</Grid>

			</Grid>
          

            </Container>
        </div>

        <FormContato />

        <Footer />
      </div>
  );
}

export default Lojas;


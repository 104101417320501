import React from 'react';
import Header from '../Components/Header';
import Footer from '../Components/Footer';
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import RoomTwoToneIcon from '@material-ui/icons/RoomTwoTone';
import MapTwoToneIcon from '@material-ui/icons/MapTwoTone';
import AlternateEmailTwoToneIcon from '@material-ui/icons/AlternateEmailTwoTone';
import EmailTwoToneIcon from '@material-ui/icons/EmailTwoTone';

import '../Styles/Contato.css';
import TitlePage from '../Components/TitlePage';
//import FormContato from '../Components/FormContato';

import MetaTags from 'react-meta-tags';



function Contato() {
    return (
     <div className="contato">

         <MetaTags>
            <title>Contato - Grande Loja Maçônica Mista do Brasil</title>
            <meta name="description" content="Associação Universal e Fraterna, de pessoas de ambos os sexos, libertas de convenções e preconceitos, socialmente livres, tendo por objetivo promover a evolução da Humanidade." />
          </MetaTags>

        <Header />

        <TitlePage  title="Contato" subtitle="Para entrar em contato com a Grande Loja Maçônica Mista do Brasil"/>

        <div className="info-contato">
            <Container maxWidth="lg">

            <div className="grid">
            <Grid container spacing={3}>
                <Grid  item xs={12} sm={3} lg={3}>
                <div className="box-contact">
                    <RoomTwoToneIcon className="icon-info"/>
                    <h3>Sede</h3>
                    <p>Rua Professor Marcondes Domingues, 293, Parada Inglesa, São Paulo/SP</p>
                </div>
                </Grid>
                <Grid  item xs={12} sm={3} lg={3}>

                <div className="box-contact">
                    <AlternateEmailTwoToneIcon className="icon-info" />
                    <h3>Redes Sociais</h3>
                    <p>Acompanhe a Grande Loja nas redes sociais</p>
                    <a target="_blank" href="https://www.instagram.com/grandelojamista/" rel="noopener noreferrer" ><i className="fab fa-instagram"></i></a>
                  <a target="_blank" href="https://www.facebook.com/grandelojamista/" rel="noopener noreferrer"><i className="fab fa-facebook"></i></a>
                  <a target="_blank" href="https://www.youtube.com/channel/UCkLiWW554RsBy3Ro6UqvhWQ" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
                </div>
                </Grid>
                <Grid  item xs={12} sm={3} lg={3}>

                <div className="box-contact">
                    <MapTwoToneIcon className="icon-info" />
                    <h3>Lojas</h3>
                    <p>Estamos presentes em 12 cidades pelo Brasil. <Link to="/lojas" >Veja aqui </Link> a mais próxima de você.</p>
                </div>
                </Grid>
                <Grid  item xs={12} sm={3} lg={3}>

                <div className="box-contact">
                    <EmailTwoToneIcon className="icon-info" />
                    <h3>E-mail</h3>
                    <p>Dúvidas ou sugestões? Envie um e-mail para: contato@grandelojamista.com.br</p>
                </div>
                </Grid>
{/*                 <Grid item xs>
                <div className="box-contact">
                    <LiveHelpTwoToneIcon className="icon-info" />
                    <h3>FAQ</h3>
                    <p>Dúvidas? Separamos alguns questionamentos frequentes pra você. <Link to="/maconaria" > Confira</Link></p>
                </div>
                </Grid> */}
            </Grid>
            </div>

            </Container>
        </div>

        <iframe title="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3659.309565987309!2d-46.61114084951868!3d-23.48535676459987!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cef614426d6131%3A0x2968e800d8ae4d75!2sR.%20Prof.%20Marcondes%20Domingues%2C%20293%20-%20Parada%20Inglesa%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2002245-010!5e0!3m2!1spt-BR!2sbr!4v1591462560203!5m2!1spt-BR!2sbr" width="100%" height="300"  aria-hidden="false" ></iframe>


        <Footer />
      </div>
  );
}

export default Contato;


import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';


import '../Styles/Footer.css';


function Footer() {
    return (
     <div className="footer">

      <Container maxWidth="lg">

       <Grid container  direction="row"  justify="space-between" alignItems="center" spacing={3}>              

          <Grid item xs={12} sm={7} lg={8}>
          <div className="allrights">
             <p>Copyright ©  - <strong>Grande Loja Maçônica Mista do Brasil</strong> (Filiada ao Clipsas)</p>
          </div>
          </Grid>

          <Grid item xs={12} sm={3} lg={2}>
                
                <div className="socialMedia">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/grandelojamista/" ><i className="fab fa-instagram"></i></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/grandelojamista/" ><i className="fab fa-facebook"></i></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCkLiWW554RsBy3Ro6UqvhWQ" ><i className="fab fa-youtube"></i></a>
                </div>

                
                {/* <div className="fb-like" data-href="https://www.facebook.com/GLMMB.1968/" data-width="" data-layout="button_count" data-action="like" data-size="small" data-share="true"></div> */}
          </Grid>
      </Grid>

      </Container>



      <div className="float-button">
         <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/grandelojamista/" ><i className="fab fa-instagram"></i></a>
         <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/grandelojamista/" ><i className="fab fa-facebook"></i></a>
         <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCkLiWW554RsBy3Ro6UqvhWQ" ><i className="fab fa-youtube"></i></a>
         <a  href="mailto:contato@grandelojamista.com.br" ><i class="fas fa-envelope"></i></a>
       </div>
      </div>

  );
}

export default Footer;

